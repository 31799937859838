import React from 'react'

export default class NotFound extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className='app-wrapper'>

      </div>
    )
  }
}
